<template>
  <div class="auth-device-detail">
    <app-detail :opt="opt" ref="detail">
    <template slot="apartment">
      <el-cascader
          class="cascade_box"
          v-model="value"
          :options="options"
          :key="isResouceShow"
          :props="{ multiple: true,}"
          clearable
          :disabled="cityChoose"
          @change="changeLabel"
        ></el-cascader>
    </template>
    </app-detail>
  </div>
</template>
<script>

import {getAccessControlTypes} from "@utils/acManager";

export default {
  data() {
    let _this = this;
    return {
      value: [],
      shareScopeEnd: [],
      isResouceShow:0,
      options: [],
      cityChoose:true,
      SNid:'',
      id:'',
      opt: {
        id: "",
        tenantId: "",
        title: "门禁详情",
        form: [
          {
            label: "省份",
            key: "province",
            type: "selectCity",
            opt: {
              list: [],
              change(data) {
                _this.getCityList(data);
              }
            },
            isCol50: true,
            isWidth67: true,
            rules: [{ required: true }]
          },
          {
            label: "城市",
            key: "city",
            type: "selectCity",
            opt: {
              list: [],
              parent: "province",
              change(data) {
                _this.getApartmentList(data);
                _this.cityChoose =  false
              }
            },
            isCol50: true,
            isWidth67: true,
            rules: [{ required: true }]
          },
          {
            label: "设备型号",
            key: "deviceCode",
            type: "select",
            isCol50: true,
            isWidth67: true,
            opt: {
              list: [],
              change(data) {
                _this.getSN(data)
              }
            },
            rules: [{ required: true }]
          },
          {
            label: "设备SN",
            key: "serialNumber",
            type: "selectCity",
            isCol50: true,
            isWidth67: true,
            opt: {
              list: [],
              parent: "deviceCode",
              change(data) {
                _this.changeSN(data);
              }
            },
            rules: [{ required: true }]
          },
          {
            label: "门禁机名称",
            key: "name",
            isCol50: true,
            isWidth67: true,
            rules: [{ required: true }]
          },
          {
            type:'slot',
            label:'房源名称',
            key: 'value',
            opt: {
              slotName: 'apartment'
            },
            rules: [{ required: true }]
          },
          {
            label: "安装位置",
            key: "location"
          },
          {
            label: "备注信息",
            key: "remark",
            type: "textarea",
          }
        ],
        buttons: [
          {
            name: "提交",
            show: ["add", "edit"],
            cb(form) {
              if(_this.value.length < 1) {
                return _this.$message({
                  message: "请选择房源名称",
                  type: "warning"
                });
              }
              let landlordAuthRelList = [];
              let buildings = [];
              _this.value.forEach(item => {
                landlordAuthRelList.push({
                  apartmentId: item[0],
                  buildingNo: item[1].split("-")[1],
                });
                buildings.push(item[1].split("-")[1])
              });

              let dto = {
                id: _this.SNid,
                province: form.province,
                city: form.city,
                remark: form.remark,
                name: form.name,
                deviceCode: form.deviceCode,
                serialNumber: form.serialNumber,
                location: form.location,
                apartmentId:landlordAuthRelList[0].apartmentId,
                buildings:buildings
              };
              _this.options.forEach(item => {
                if(item.value == landlordAuthRelList[0].apartmentId) {
                  dto.apartmentName = item.label;
                }
              })
              let url = "landlord-service/house/v1/r20/bind";
              let message = "添加门禁成功";
              if (_this.id) {
                url = "device-service/device/v1/r20/update";
                dto.id = _this.id;
                dto.tenantId = _this.tenantId; 
                message = "编辑门禁成功";
              }

              _this.post(url, dto).then(function() {
                _this.$message({
                  showClose: true,
                  message: message,
                  type: "success"
                });
                _this.$back();
              });
            }
          }
        ],
        get(opt) {
          let row = _this.$route.query.row
          let options = _this.$route.query.options
          let data = {}
          data.province = row.province
          data.city = row.city
          data.serialNumber = row.serialNumber
          data.name = row.name
          data.deviceCode = row.deviceCode
          data.apartmentName = row.apartmentName
          data.location = row.location
          data.remark = row.remark
          data.state = ["禁用", "正常"][row.state]
          let valueList = []
          let apartmentName = row.apartmentName
          row.buildings.forEach(val => {
            options.forEach(item => {
              if(item.label == apartmentName) {
                valueList.push([item.value, item.value + '-' + val])
              } else {
                return
              }
            })
          })
          _this.value = valueList
          opt.cb(data);
        }
      },
      apartmentId: "", // 房源id
      shareScopeEnd: [],
    };
  },
  watch: {
    config: {
      handler() {
        this.isResouceShow++;
      },
      deep: true,
    },
  },
  activated() {
    this.$refs.detail.title = ["添加门禁", "编辑门禁", "门禁详情"][
      {
        add: 0,
        edit: 1,
        show: 2
      }[this.$route.params.type]
    ];
    console.log(this.$route);
    console.log(this.$route.params);
    console.log("room-detail created!!");
    this.apartmentId = this.$route.query.apartmentId || "";
    this.getSN();
    this.onclickProvince();
    this.getApartmentList()
    this.getAccessControlTypeList();
    this.cityChoose = true
    if (this.$route.params.id != "0") {
      this.id = this.$route.params.id;
      this.tenantId = this.$route.query.tenantId == "undefined"? "": this.$route.query.tenantId;
    } else {
      this.id = "";
      this.tenantId = "";
      this.value = [];
      this.opt.form[5].rules[0].required = true;
    }
  },
  methods: {
    onclickProvince() {
      this.post("/landlord-service/base/getProvinces").then(res => {
        for (let i = 0; i < this.opt.form.length; i++) {
          let data = this.opt.form[i];
          if (data.key === "province") {
            res = JSON.parse(JSON.stringify(res).replace(/name/g, "label"));
            res = JSON.parse(JSON.stringify(res).replace(/id/g, "value"));
            data.opt.list = res;
            break;
          }
        }
      });
    },
    // 获取城市列表
    getCityList(name) {
      this.post("/landlord-service/base/getCitys?name=" + name).then(res => {
        for (let i = 0; i < this.opt.form.length; i++) {
          let data = this.opt.form[i];
          if (data.key === "city") {
            res = JSON.parse(JSON.stringify(res).replace(/name/g, "label"));
            res = JSON.parse(JSON.stringify(res).replace(/id/g, "value"));
            data.opt.list = res;
            break;
          }
        }
      });
    },
    //获取SN
    getSN(value) {
      return new Promise((resolve, reject) => {
        let dto = {
          deviceModel: value || 'R50Y'
        }
        this.post("landlord-service/house/v1/r20/find-sn",dto).then(res => {
          this.opt.form.forEach(data => {
            if (data.key === "serialNumber") {
              let list = [];
              res.forEach(item => {
                list.push({ label: item.serialNumber, value: item.serialNumber, id: item.id });
              });
              data.opt.list = list;
              resolve(res);
            }   
          });
        });
      });
    },
    //获取房源列表
    getApartmentList(cityId) {
      var dto = {
        pageNumber: "1",
        pageSize: "99999",
        city: cityId
      };
      this.post("landlord-service/apartment/queryPagesApartment", dto).then(
        res => {
              let tempListArray = [];
              res.forEach((item, index) => {
                item.label = item.name;
                item.value = item.id;
                tempListArray.push({
                  label: item.label,
                  value: item.value,
                  children: []
                });
                item.buildingList.forEach(data => {
                  tempListArray[index]["children"].push({
                    label: `${data.buildName}`,
                    value: `${item.value}-${data.buildCode}`
                  });
                });
              });
            let optionsList = tempListArray.filter(item => item.children.length > 0);
            this.options = optionsList
            ++this.isResouceShow 
            }
      );
    },
    // 获取门禁类型
    getAccessControlTypeList() {
      this.opt.form.find(data => data.key === 'deviceCode')
          .opt.list = getAccessControlTypes()
    },
    // 限制级联选择器一级单选二级多选
    changeLabel(val) {
      // 是否与上次的类型相同
      let changeFlag = false
      let changeItem = null
      if (this.shareScopeEnd.length == 0) {
        this.value = val
      } else {
        // 与原数组比对
        this.value.forEach((item) => {
          if (item[0] !== this.shareScopeEnd[0][0]) { // 一级标签不同
            changeFlag = true
            changeItem = item
          } 
        })
      }
      if (changeFlag) {
        this.value = []
        this.value.push(changeItem)
      }
      this.shareScopeEnd = this.value
      if(this.value.length > 0 ) {
        this.opt.form[5].rules[0].required = undefined
        document.getElementsByClassName('cascade_box')[0].parentNode.parentNode.previousSibling.classList.add('cascade_label')
      } else {
        this.opt.form[5].rules[0].required = true
      }
    },
    // 切换设备SN绑定id
    changeSN(data) {
      if (this.$route.params.id != "0") {
        return
      } else {
        var index = this.opt.form[3].opt.list.findIndex(item => item.value=== data)
        this.SNid = this.opt.form[3].opt.list[index].id
      }
      
    },
  
  }
};
</script>
<style lang="scss">
.cascade_label:before {
    content: "*";
    color: #ff4949;
    margin-right: 4px;
  }
</style>