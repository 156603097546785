var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "auth-device-detail" },
    [
      _c(
        "app-detail",
        { ref: "detail", attrs: { opt: _vm.opt } },
        [
          _c(
            "template",
            { slot: "apartment" },
            [
              _c("el-cascader", {
                key: _vm.isResouceShow,
                staticClass: "cascade_box",
                attrs: {
                  options: _vm.options,
                  props: { multiple: true },
                  clearable: "",
                  disabled: _vm.cityChoose
                },
                on: { change: _vm.changeLabel },
                model: {
                  value: _vm.value,
                  callback: function($$v) {
                    _vm.value = $$v
                  },
                  expression: "value"
                }
              })
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }