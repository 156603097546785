/**
 * 门禁型号列表
 */
export function getAccessControlTypes() {
  return [{label: "WF-R20", value: "R20"}, {label: "WF-R50Y", value: "R50Y"}]
}

/**
 * 门禁类型值转model
 * @param type
 */
export function accessControlType2Model(type) {
  let findType = getAccessControlTypes().find(it => it.value === type);
  return findType ? findType.label : '--';
}